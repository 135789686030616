/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getAllImagesFromContent } from '../../domain/utils/contentUtils/image/imageUtils';
import { resourceService } from '../../domain/services/resourceService';
import { CardImage } from './CardImage';
import { CardText } from './CardText';

export const CardItem = ({
  data,
  imageMaxWidths,
  aspect,
  styles,
  isAboveTheFold,
  canBeAnimated
}) => {
  const [animatedImageEntities, setAnimatedImageEntities] = useState([]);
  const dataLayerClickEvent =
    animatedImageEntities.length > 1
      ? { event: 'click_animated', link_title: data.title }
      : undefined;
  useEffect(() => {
    if (
      window.___ng_animatedImagesAlreadyRendered !== window.location.pathname &&
      canBeAnimated &&
      data.isAnimated &&
      data.url
    ) {
      // Setting animatedImagesAlreadyRendered here, before getting the contents
      // of page-data.json and checking if there are any image is an optimistic action.
      // Anyway, nearly all times an image is found, so moving it here
      // avoids having to fetch more than one page-data.json when a homepage/hub_page
      // contains several blocks that can be animated.
      window.___ng_animatedImagesAlreadyRendered = window.location.pathname;
      resourceService.getJsonForPathname(data.url).then(content => {
        const images = getAllImagesFromContent(content);
        if (images.length > 1) {
          setAnimatedImageEntities(images);
        }
        return true;
      });
    }

    if (!canBeAnimated && animatedImageEntities.length > 0) {
      setAnimatedImageEntities([]);
    }

    return () => {};
  }, [canBeAnimated]);

  return (
    <section css={styles.container}>
      {data.imageEntity ? (
        <CardImage
          type={data.type}
          url={data.url}
          onClick={data.onClick}
          target={data.target}
          title={data.title}
          imageEntity={data.imageEntity}
          imageMaxWidths={imageMaxWidths}
          aspect={aspect}
          isAboveTheFold={isAboveTheFold}
          styles={styles.image}
          animatedImageEntities={animatedImageEntities}
          dataLayerClickEvent={dataLayerClickEvent}
        />
      ) : null}
      <CardText
        type={data.type}
        url={data.url}
        onClick={data.onClick}
        target={data.target}
        title={data.title}
        subtitle={data.subtitle}
        videoDuration={data.videoDuration}
        sponsored={data.sponsored}
        taxonomies={data.taxonomies}
        showCTA={data.showCTA}
        styles={styles.text}
        dataLayerClickEvent={dataLayerClickEvent}
      />
    </section>
  );
};

CardItem.defaultProps = {
  imageMaxWidths: undefined,
  aspect: undefined,
  isAboveTheFold: false,
  canBeAnimated: false
};

CardItem.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.oneOf([
      'article',
      'video',
      'show',
      'photo_gallery',
      'hub_page',
      'page'
    ]).isRequired,
    url: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    target: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    imageEntity: PropTypes.objectOf(PropTypes.any),
    videoDuration: PropTypes.number,
    sponsored: PropTypes.objectOf(PropTypes.any),
    taxonomies: PropTypes.arrayOf(PropTypes.any),
    animatedImageEntities: PropTypes.arrayOf(PropTypes.any),
    isAnimated: PropTypes.bool,
    showCTA: PropTypes.bool
  }).isRequired,
  imageMaxWidths: PropTypes.objectOf(PropTypes.any),
  aspect: PropTypes.any,
  styles: PropTypes.shape({
    container: PropTypes.any.isRequired,
    image: PropTypes.shape({
      container: PropTypes.any,
      picture: PropTypes.any,
      cta: PropTypes.any
    }).isRequired,
    text: PropTypes.shape({
      container: PropTypes.any,
      sponsored: PropTypes.any,
      taxonomy: PropTypes.any,
      title: PropTypes.any,
      subtitle: PropTypes.any,
      cta: PropTypes.any
    }).isRequired
  }).isRequired,
  isAboveTheFold: PropTypes.bool,
  canBeAnimated: PropTypes.bool
};
