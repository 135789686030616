import { css } from '@emotion/react';
import { theme } from '../../../../domain/theme';
import { contentPackageSmallStyle } from '../../ContentPackage/style/contentPackageSmallStyle';

export const leftProminentStyle = bgColor => {
  const smallStyle = contentPackageSmallStyle(bgColor);

  return {
    container: smallStyle.container,
    image: {
      container: smallStyle.image.container,
      picture: smallStyle.image.picture,
      cta: smallStyle.image.cta
    },
    text: {
      container: smallStyle.text.container,
      sponsored: smallStyle.text.sponsored,
      taxonomy: smallStyle.text.taxonomy,
      title: [
        smallStyle.text.title,
        css`
          -webkit-line-clamp: 4;
          ${theme.mq.tablet} {
            -webkit-line-clamp: 4;
          }
        `
      ],
      subtitle: [
        smallStyle.text.subtitle,
        css`
          -webkit-line-clamp: 5;
          ${theme.mq.tablet} {
            -webkit-line-clamp: 4;
          }
        `
      ],
      cta: smallStyle.text.cta
    }
  };
};
