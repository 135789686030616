import React from 'react';
import PropTypes from 'prop-types';
import { getCardDatum } from '../shared/getCardDatum';
import { CardItem } from '../../../Card/CardItem';
import { rightMediumContentMediumStyle } from './rightMediumContentMediumStyle';
import { rightMediumContentSmallStyle } from './rightMediumContentSmallStyle';
import { ContainerFluid } from '../../../responsive/atoms/ContainerFluid';
import { GridColumn, GridRow } from '../../../responsive/atoms/Grid';

export const RightContentPackage = ({
  data,
  backgroundColor,
  isAboveTheFold
}) => {
  const mediumContentCardDatum = getCardDatum([data.mediumContents]);
  const smallContentCardDatum = getCardDatum(data.smallContents);

  const mediumStyle = rightMediumContentMediumStyle(backgroundColor);
  const smallStyle = rightMediumContentSmallStyle(backgroundColor);

  return (
    <>
      {mediumContentCardDatum[0] && (
        <CardItem
          styles={mediumStyle}
          data={mediumContentCardDatum[0]}
          aspect="16x9"
          imageMaxWidths={{
            mobile: 767,
            tablet: 559,
            small_desktop: 813,
            large_desktop: 877
          }}
          isAboveTheFold={isAboveTheFold}
          key="medium-content-0"
        />
      )}

      <ContainerFluid>
        <GridRow gutter={[null, null, '16px', '20px']}>
          {smallContentCardDatum.map(smallContentCardData => {
            return (
              <GridColumn
                width={[12, 12, 6, 6]}
                childrenMarginBottom="l"
                key={smallContentCardData.title}
              >
                <CardItem
                  styles={smallStyle}
                  data={smallContentCardData}
                  aspect="16x9"
                  imageMaxWidths={{
                    mobile: 727,
                    tablet: 559,
                    small_desktop: 390,
                    large_desktop: 419
                  }}
                  isAboveTheFold={isAboveTheFold}
                />
              </GridColumn>
            );
          })}
        </GridRow>
      </ContainerFluid>
    </>
  );
};

RightContentPackage.defaultProps = {
  isAboveTheFold: false
};

RightContentPackage.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  backgroundColor: PropTypes.string.isRequired,
  isAboveTheFold: PropTypes.bool
};
