import { mapContentToCardData } from '../../../../domain/utils/contentUtils';

export const getCardDatum = data => {
  const cardDatum = data.map(item => {
    // data can be an already mapped object coming from src/components/HubPage/TermViewHubpage.jsx
    // In such case, do not try to map it again.
    if (item?.props?.data) {
      return item.props.data;
    }
    const cardDataFields = [
      'subtitle',
      'taxonomies',
      'videoDuration',
      'sponsored'
    ];

    const cardData = mapContentToCardData(
      item.entity.itemRef.entity,
      cardDataFields
    );

    if (item.entity.showCTA) {
      cardData.showCTA = true;
    }

    return cardData;
  });

  return cardDatum;
};
